import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";

const Video = (props) => {
  const ref = useRef();

  useEffect(() => {
    props.peer.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
  }, [props.peer]);

  return (
    <div className="w-1/2">
      <div className="relative pb-1x1 rounded-full overflow-hidden">
        <video
          className="absolute inset-0 h-full w-full object-contain"
          playsInline
          autoPlay
          ref={ref}
        />
      </div>
    </div>
  );
};

const videoConstraints = {
  height: 720,
  width: 720,
  facingMode: "user",
};

const Room = (props) => {
  const [peers, setPeers] = useState([]);
  const socketRef = useRef();
  const userVideo = useRef();
  const peersRef = useRef([]);
  const roomID = props.match.params.roomID;

  useEffect(() => {
    socketRef.current = io.connect("/");
    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then((stream) => {
        userVideo.current.srcObject = stream;
        socketRef.current.emit("join room", roomID);
        socketRef.current.on("all users", (users) => {
          const peers = [];
          users.forEach((userID) => {
            const peer = createPeer(userID, socketRef.current.id, stream);
            const peerPayload = {
              peerID: userID,
              peer,
            }
            peersRef.current.push(peerPayload);
            peers.push(peerPayload);
          });
          setPeers(peers);
        });

        socketRef.current.on("user joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream);
          const peerPayload = {
            peerID: payload.callerID,
            peer,
          }
          peersRef.current.push(peerPayload);
          setPeers((users) => [...users, peerPayload]);
        });

        socketRef.current.on("user left", (payload) => {
          console.log({payload, peersRef, peers})

          // Update ref and state
          peersRef.current = peersRef.current.filter(item => item.peerID !== payload.userId);
          setPeers((users) => users.filter(item => item.peerID !== payload.userId));
        });

        socketRef.current.on("receiving returned signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item.peer.signal(payload.signal);
        });
      });
  }, [roomID]);

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sending signal", {
        userToSignal,
        callerID,
        signal,
      });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  return (
    <div className="p-4">
      <div className="flex flex-wrap">
        <div className="w-1/2">
          <div className="relative pb-1x1 rounded-full overflow-hidden">
            <video
              className="absolute inset-0 h-full w-full object-contain"
              playsInline
              autoPlay
              muted
              ref={userVideo}
            />
          </div>
        </div>
        {peers.map((peer, index) => {
          return <Video key={peer.peerID} peer={peer.peer} />;
        })}
      </div>
    </div>
  );
};

export default Room;
