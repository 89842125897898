import React from "react";
import { nanoid } from "nanoid";

const CreateRoom = (props) => {
  function create() {
    const id = nanoid();
    props.history.push(`/room/${id}`);
  }

  return <button onClick={create}>Create room</button>;
};

export default CreateRoom;
